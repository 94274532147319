import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import { standardsAtom } from "../../recoil/standardsAtom";
// import UploadEurekaLessonsForm from
import { EurekaLesson, EurekaLessonRecord, Standard } from "../../types/types";
import UploadEurekaLessonsForm from "./UploadEurekaLessonsForm";
import { eurekaLessonsAtom } from "../../recoil/atoms";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

enum Column {
    Subject = "Subject",
  Grade = "Grade",
 
  Module="Module",
  Topic="Topic",
  Lessons="Lessons",
  Standard="Standard"
  
}

type UploadDictionary = {
  [key in Column]: string;
};

const UploadEurekaLessonsContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const eurekaLessons = useRecoilValue(eurekaLessonsAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);

  const searchRecords = (newRecord: EurekaLesson ) => {
    if (!eurekaLessons) return;
    const matchingRecords = eurekaLessons.filter(
      (existingRecord) =>
        // existingRecord.standardNumber === newRecord.standardNumber &&
        existingRecord.subject === newRecord.subject &&
        existingRecord.grade === newRecord.grade &&
        existingRecord.module === newRecord.module &&
        existingRecord.topic === newRecord.topic &&
        existingRecord.lessons === newRecord.lessons &&
        existingRecord.standard === newRecord.standard 

    );
    return matchingRecords.length > 0 ? true : false;
  };
  const fromWebsite = async (value: UploadDictionary) => {
    const record : EurekaLesson= {
     
      grade: value[Column.Grade] || "",
      
      subject: value[Column.Subject] || "",
      module: value[Column.Module] || "",
      topic: value[Column.Topic] || "",
      lessons: value[Column.Lessons] || "",
      standard :value[Column.Standard] || "",
    //   id: "",
    };

   

    const found = searchRecords(record);
    let resultId: string | null = "";
    
    if (!found) {
        resultId = await addDoc({ col: "eurekaLessons", data: record });
    }
    return resultId ? resultId : "";
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<UploadDictionary>(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            const resultId = fromWebsite(value);
            promises.push(resultId);
          });
        },
        header: true,
      });
      await Promise.all(promises);
      setLoading(false);
    }
  };

  return (
    <UploadEurekaLessonsForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
    />
  );
};

export default  UploadEurekaLessonsContainer ;
