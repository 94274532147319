import { createTheme } from "@mui/material/styles";

declare module "@mui/material/Chip" {
  export interface ChipPropsColorOverrides {
    gray: true;
    notMetProficiency: true;
    approachesProficiency: true;
    metProficiency: true;
    masteredProficiency: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    cardTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
  }
}

declare module "@mui/material/styles" {
  interface PaletteOptions {
    gray: {
      main: string;
      contrastText: string;
    };
    notMetProficiency: {
      main: string;
      contrastText: string;
    };
    approachesProficiency: {
      main: string;
      contrastText: string;
    };
    metProficiency: {
      main: string;
      contrastText: string;
    };
    masteredProficiency: {
      main: string;
      contrastText: string;
    };
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    leadCardTitle: true;
    leadCardDate: true;
    leadCardInfo: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#26C6F5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#B930E6",
    },
    gray: {
      main: "#ccc",
      contrastText: "#000",
    },
    notMetProficiency: {
      main: "#E54A4A",
      contrastText: "#fff",
    },
    approachesProficiency: {
      main: "#E6C960",
      contrastText: "#000",
    },
    metProficiency: {
      main: "#32E665",
      contrastText: "#000",
    },
    masteredProficiency: {
      main: "#3E4DE6",
      contrastText: "#fff",
    },
  },
  typography: {
    cardTitle: {
      fontSize: 24,

      fontWeight: 100,
    },
    h1: {
      fontSize: 80,
      fontFamily: "Source Sans 3, sans-serif",
      fontWeight: 300,
    },
    h2: {
      fontFamily: "Merriweather, serif",
      fontSize: 50,
      fontWeight: 900,
      color: "#333",
    },
    h3: {
      fontSize: 45,
      fontFamily: "Source Sans 3, sans-serif",
      fontWeight: 600,
    },
    h4: {
      fontSize: 34,
      fontFamily: "Merriweather, serif",
      fontWeight: 600,
    },
    h5: {
      fontSize: 32,
      fontFamily: "Source Sans 3, sans-serif",
      fontWeight: 900,
    },
    h6: {
      fontSize: 28,
      fontFamily: "Source Sans 3, sans-serif",
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      fontFamily: "Source Sans 3, sans-serif",
    },
    body2: {
      fontSize: 16,
      fontFamily: "Source Sans 3, sans-serif",
    },
  },
});
