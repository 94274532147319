import { useEffect, useContext, useState } from "react";
import useGetDocs from "./useGetDocs";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { staffAtom, loggedInStaffAtom } from "../recoil/staffAtoms";
import {
  EurekaLessonRecord,
  OverviewData,
  OverviewDataRecord,
  ScoreRecord,
  SkillRecord,
  Staff,
  StandardRecord,
} from "../types/types";
import { AuthContext } from "../providers/AuthProvider";
import {
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs as getFBDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  parseOverviewsResponse,
  parseScoresResponse,
  parseSkillsResponse,
  parseEurekaLessonResponse,
  parseStaffResponse,
  parseStandardsResponse,
} from "../libraries/parsers";
import { standardsAtom, standardsResetAtom } from "../recoil/standardsAtom";
import { skillsAtom, skillsResetAtom } from "../recoil/skillsAtom";
import { scoresAtom, scoresLoadedAtom, scoresResetAtom } from "../recoil/scoresAtom";
import { overviewsAtom, overviewsResetAtom } from "../recoil/overviewsAtom";
import { eurekaLessonsAtom } from "../recoil/atoms";
import { selectedHomeroomIdsAtom } from "../recoil/filtersAtom";
import { filteredStudentsAtom, studentsAtom } from "../recoil/atoms";

const useBootstrapEffect = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const { currentAuthUser } = useContext(AuthContext);
  const staff = useRecoilValue(staffAtom);
  const setStaff = useSetRecoilState(staffAtom);
  const setLoggedInStaff = useSetRecoilState(loggedInStaffAtom);
  const setStandards = useSetRecoilState(standardsAtom);
  const setSkills = useSetRecoilState(skillsAtom);
  const setScores = useSetRecoilState(scoresAtom);
  const setOverviews = useSetRecoilState(overviewsAtom);
  //const setEurekaLessons=useSetRecoilState(eurekaLessonsAtom);
  const [eurekaLessons, setEurekaLessons] = useRecoilState(eurekaLessonsAtom);
  const setScoresLoaded = useSetRecoilState(scoresLoadedAtom);
  //RESETS
  const standardsReset = useRecoilValue(standardsResetAtom);
  const skillsReset = useRecoilValue(skillsResetAtom);
  const scoresReset = useRecoilValue(scoresResetAtom);
  const overviewsReset = useRecoilValue(overviewsResetAtom);
  const selectedHomeroomIds = useRecoilValue(selectedHomeroomIdsAtom);
  const filteredStudents = useRecoilValue(filteredStudentsAtom);
  const [delayCounter, setDelayCounter] = useState(1);

  useEffect(() => {
    const getEurekaLessons = async () => {
      const response = await getDocs<EurekaLessonRecord>({ col: "eurekaLessons" });
      console.log("response: ", response);
      if (response) {
        console.log(response);
        setEurekaLessons(response);
      } else {
        console.log("error fetching data");
      }
    };
    getEurekaLessons();
  }, [setEurekaLessons, getDocs]);

  useEffect(() => {
    const getStaff = async () => {
      const response = await getDocs<Staff>({ col: "staff" });
      if (response) {
        setStaff(parseStaffResponse(response));
      }
    };
    getStaff();
  }, [setStaff, getDocs]);

  useEffect(() => {
    if (delayCounter !== 1) return;
    const getStandards = async () => {
      const response = await getDocs<StandardRecord>({ col: "standards" });
      setDelayCounter((pV) => pV + 1);
      console.log(delayCounter);
      if (response) {
        setStandards(parseStandardsResponse(response));
      }
    };
    getStandards();
  }, [setStandards, getDocs, standardsReset, delayCounter]);

  useEffect(() => {
    if (delayCounter !== 2) return;
    const getSkills = async () => {
      const response = await getDocs<SkillRecord>({ col: "skills" });
      setDelayCounter((pV) => pV + 1);
      if (response) {
        setSkills(parseSkillsResponse(response));
      }
    };
    getSkills();
  }, [setSkills, getDocs, skillsReset, delayCounter]);

  useEffect(() => {
    if (delayCounter !== 3) return;
    const getOverviews = async () => {
      const response = await getDocs<OverviewDataRecord>({ col: "overviews" });
      setDelayCounter((pV) => pV + 1);
      if (response) {
        setOverviews(parseOverviewsResponse(response));
      }
    };
    getOverviews();
  }, [setOverviews, getDocs, overviewsReset, delayCounter]);

  useEffect(() => {
    if (!currentAuthUser) return;
    const getScores = async () => {
      setScoresLoaded(false);
      const tempArray: ScoreRecord[] = [];
      const docRef = collection(db, "scores");
      const pageLimit = 5000;
      let snapshotLength = 0;
      const q = query(docRef, orderBy("sid", "desc"), limit(pageLimit));
      const firstSnapshot = await getFBDocs(q);
      snapshotLength = firstSnapshot.docs.length;
      let lastVisible = firstSnapshot.docs[snapshotLength - 1];
      firstSnapshot.forEach((s) => {
        tempArray.push({ ...s.data(), id: s.id } as ScoreRecord);
      });
      setScores(tempArray);
      while (snapshotLength !== 0) {
        const nextSnapshot = await getFBDocs(
          query(docRef, orderBy("sid", "desc"), startAfter(lastVisible), limit(pageLimit))
        );
        snapshotLength = nextSnapshot.docs.length;
        lastVisible = nextSnapshot.docs[snapshotLength - 1];
        const tempArray: ScoreRecord[] = [];
        nextSnapshot.forEach((s) => {
          tempArray.push({ ...s.data(), id: s.id } as ScoreRecord);
        });
        setScores((pV) => [...pV, ...tempArray]);
      }
      setScoresLoaded(true);
    };
    getScores();
  }, [currentAuthUser, setScores, setScoresLoaded]);

  useEffect(() => {
    if (!currentAuthUser || staff.length === 0) return;
    const filteredStaff = staff.filter(
      (staffMember) => staffMember.email === currentAuthUser.email
    );
    if (filteredStaff.length === 1) {
      setLoggedInStaff(filteredStaff[0]);
    } else {
      //generate message
      console.error(
        "[Hook] useBootstrapEffect - filtered Staff, no DB user found or multiple DB users found",
        currentAuthUser.email,
        filteredStaff.length
      );
    }
  }, [currentAuthUser, staff, setLoggedInStaff]);
};

export default useBootstrapEffect;
