import { Box, Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import UploadEurekaLessonsContainer from "../components/UploadEurekaLessons/UploadEurekaLessonsContainer";


const UploadEurekaLessonsPage= () => {
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Navbar />
        <Container maxWidth="xl" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
          <UploadEurekaLessonsContainer/>
        </Container>
      </Box>
    </>
  );
};

export default UploadEurekaLessonsPage;