import { Box, Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import UploadStandardsContainer from "../components/UploadStandards/UploadStandardsContainer";
import UploadOverviewsContainer from "../components/UploadOverviews/UploadOverviewsContainer";

const UploadOverviewsPage = () => {
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Navbar />
        <Container maxWidth="md" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
          <UploadOverviewsContainer />
        </Container>
      </Box>
    </>
  );
};

export default UploadOverviewsPage;
