import { Box, Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import UploadStandardsContainer from "../components/UploadStandards/UploadStandardsContainer";
import UploadSkillsContainer from "../components/UploadSkills/UploadSkillsContainer";

const UploadSkillsPage = () => {
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Navbar />
        <Container maxWidth="md" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
          <UploadSkillsContainer />
        </Container>
      </Box>
    </>
  );
};

export default UploadSkillsPage;
