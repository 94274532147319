import { SyntheticEvent } from "react";
import {
  Avatar,
  Box,
  Paper,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from "@mui/material";
import { SUBJECT, TERM } from "../../libraries/objects";

type Props = {
  loading: boolean;
  successMessage: string;
  changeHandler: (event: SyntheticEvent) => void;
  handleSubmission: () => void;
  selectedFile: File | null | undefined;
  term: string;
  handleTermChange: (event: SelectChangeEvent) => void;
  handleSubjectChange: (event: SelectChangeEvent) => void;
  subject: string;
};

export default function UploadScoresForm({
  loading,
  successMessage,
  changeHandler,
  handleSubmission,
  selectedFile,
  term,
  handleTermChange,
  subject,
  handleSubjectChange,
}: Props) {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "1em",
        }}
      >
        <Avatar sx={{ width: 120, height: 120, mb: 3 }} src="../TGPicon.png" />
      </Box>
      <Paper>
        <Box sx={{ textAlign: "center", pt: 3 }}>
          <Typography variant="h1">Upload Scores</Typography>
          <FormControl required sx={{ m: 2, minWidth: 150 }}>
            <InputLabel id="term-select">Select Term</InputLabel>
            <Select
              value={term}
              onChange={handleTermChange}
              label="Select Term"
              labelId="term-select"
            >
              <MenuItem value={TERM.FALL_2324}>{TERM.FALL_2324}</MenuItem>
              <MenuItem value={TERM.WINTER_2324}>{TERM.WINTER_2324}</MenuItem>
              <MenuItem value={TERM.SPRING_2324}>{TERM.SPRING_2324}</MenuItem>
            </Select>
          </FormControl>
          <FormControl required sx={{ m: 2, minWidth: 150 }}>
            <InputLabel id="subject-select">Select Subject</InputLabel>
            <Select
              value={subject}
              onChange={handleSubjectChange}
              label="Select Subject"
              labelId="subject-select"
            >
              <MenuItem value={SUBJECT.MATH}>{SUBJECT.MATH}</MenuItem>
              <MenuItem value={SUBJECT.RLA}>{SUBJECT.RLA}</MenuItem>
              <MenuItem value={SUBJECT.SCIENCE}>{SUBJECT.SCIENCE}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ textAlign: "center", padding: 3 }}></Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              m: 2,
              mb: 2,
            }}
          >
            <CircularProgress size={60} sx={{ mb: 2 }} />
          </Box>
        ) : (
          <Box sx={{ textAlign: "center" }}>
            {successMessage && <Typography variant="h4">{successMessage}</Typography>}
            <input
              id="uploadFile"
              type="file"
              name="file"
              style={{ display: "none" }}
              onChange={changeHandler}
            />
            <label htmlFor="uploadFile">
              {" "}
              <Button variant="contained" sx={{ margin: 3 }} component="span">
                Choose File
              </Button>
            </label>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 3 }}
              onClick={handleSubmission}
            >
              Submit
            </Button>
            {selectedFile && <Typography>{selectedFile.name}</Typography>}
          </Box>
        )}
      </Paper>
    </>
  );
}
