import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import { standardsAtom } from "../../recoil/standardsAtom";
import UploadStandardsForm from "./UploadStandardsForm";
import { Standard } from "../../types/types";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

enum Column {
  Strand_Number = "Strand Number",
  Strand_Name = "Strand Name",
  Strand_Label = "Strand Label",
  Standard_Number = "Standard Number",
  Standard_Number_MAP = "Standard Number MAP",
  Standard_Label = "Standard Label",
  Grade = "Grade",
  Subject = "Subject",
  Type = "Type",
}

type UploadDictionary = {
  [key in Column]: string;
};

const UploadStandardsContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const standards = useRecoilValue(standardsAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);

  const searchRecords = (newRecord: Standard) => {
    if (!standards) return;
    const matchingRecords = standards.filter(
      (existingRecord) =>
        existingRecord.standardNumber === newRecord.standardNumber &&
        existingRecord.grade === newRecord.grade &&
        existingRecord.subject === newRecord.subject
    );
    return matchingRecords.length > 0 ? true : false;
  };
  const fromWebsite = async (value: UploadDictionary) => {
    const record = {
      strandNumber: value[Column.Strand_Number] || "",
      strandName: value[Column.Strand_Name] || "",
      strandLabel: value[Column.Strand_Label] || "",
      standardNumber: value[Column.Standard_Number] || "",
      standardNumberMAP: value[Column.Standard_Number_MAP] || "",
      standardLabel: value[Column.Standard_Label] || "",
      grade: value[Column.Grade] || "",
      type: value[Column.Type] || "",
      subject: value[Column.Subject] || "",
      id: "",
    };

    const found = searchRecords(record);
    let resultId: string | null = "";
    if (!found) {
      resultId = await addDoc({ col: "standards", data: record });
    }
    return resultId ? resultId : "";
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<UploadDictionary>(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            const resultId = fromWebsite(value);
            promises.push(resultId);
          });
        },
        header: true,
      });
      await Promise.all(promises);
      setLoading(false);
    }
  };

  return (
    <UploadStandardsForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
    />
  );
};

export default UploadStandardsContainer;
