import { atom } from "recoil";
import { ScoreRecord } from "../types/types";

export const scoresAtom = atom<ScoreRecord[]>({
  key: "scores",
  default: [],
});

export const scoresResetAtom = atom({
  key: "scoresReset",
  default: false,
});

export const scoresLoadedAtom = atom({
  key: "scoresLoaded",
  default: true,
});
