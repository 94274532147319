import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { skillsAtom } from "../../recoil/skillsAtom";
import { scoresAtom } from "../../recoil/scoresAtom";
import { filteredStudentsAtom, studentsAtom } from "../../recoil/atoms";
import { Score, ScoreRecord, SkillRecord, Student } from "../../types/types";
import { makeSIDSixDigits } from "../../libraries/functions";
import StudentRow from "./StudentRow";

type Props = {
  rit: string;
  selectedStandardNumber: string;
};

const RITBox = ({ rit, selectedStandardNumber }: Props) => {
  const skills = useRecoilValue(skillsAtom);
  const scores = useRecoilValue(scoresAtom);
  const students = useRecoilValue(filteredStudentsAtom);
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  const [filteredScores, setFilteredScores] = useState<ScoreRecord[]>([]);
  const [filteredSkills, setFilteredSkills] = useState<SkillRecord[]>([]);

  useEffect(() => {
    setFilteredScores(
      scores.filter((score) => score.rit === rit && score.standardNumber === selectedStandardNumber)
    );
  }, [scores, selectedStandardNumber]);

  useEffect(() => {
    const tempFilteredSkills = skills.filter(
      (skill) => skill.rit === rit && skill.standardNumber === selectedStandardNumber
    );
    setFilteredSkills(tempFilteredSkills);
  }, [skills, selectedStandardNumber]);

  useEffect(() => {
    const filteredStudents = students.filter((student) => {
      const studentScores = filteredScores.filter((score) => {
        const sid = makeSIDSixDigits(score);
        return sid === student.SID;
      });
      return studentScores.length > 0;
    });
    setFilteredStudents(filteredStudents);
  }, [selectedStandardNumber, filteredScores, students]);

  return (
    <>
      {filteredStudents.length > 0 && (
        <Paper sx={{ textAlign: "center", mt: 2 }}>
          <Typography variant="h5">{rit}</Typography>
          <Box>
            {filteredStudents.map((student) => (
              <StudentRow key={student.id} student={student} />
            ))}
            <Typography sx={{ mt: 1 }}>
              <b>All need to work on the following skills:</b>
            </Typography>
            <Divider />
            {filteredSkills.map((skill) => (
              <Box key={skill.id} sx={{ pt: 1, pb: 1 }}>
                <Typography key={skill.id}>
                  {skill.skill === "<<No Skills for This Standard and RIT Score>>"
                    ? `${skill.skill}`
                    : `${skill.skill}`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      )}
    </>
  );
};

export default RITBox;
