import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import StandardSideMenu from "./StandardSideMenu";
import StandardRITReport from "./StandardRITReport";
import Filters from "./Filters";
import { useRecoilValue } from "recoil";
import { scoresAtom, scoresLoadedAtom } from '../../recoil/scoresAtom';

const StandardRITReportContainer = () => {
  const [selectedStandardNumber, setSelectedStandardNumber] = useState<string | null>(null);
  const scores = useRecoilValue(scoresAtom);
  const scoresLoaded = useRecoilValue(scoresLoadedAtom)
  console.log("Length of scores:", scores.length)
  return (
    <>
      {scores.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: 2,
            mb: 2,
          }}
        >
          <CircularProgress size={60} sx={{ mb: 2 }} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filters />
          </Grid>

          <Grid item xs={12} md={4} sx={{ height: "70VH", overflow: "scroll" }}>
            <StandardSideMenu
              setSelectedStandardNumber={setSelectedStandardNumber}
              selectedStandardNumber={selectedStandardNumber}
            />
          </Grid>
          <Grid item xs={12} md={8} sx={{ height: "70VH", overflow: "scroll" }}>
            <StandardRITReport selectedStandardNumber={selectedStandardNumber} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StandardRITReportContainer;
