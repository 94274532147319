import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import { standardsAtom } from "../../recoil/standardsAtom";
import UploadOverviewsForm from "./UploadOverviewsForm";
import { OverviewDataRecord, Standard } from "../../types/types";
import { overviewsAtom } from "../../recoil/overviewsAtom";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

enum Column {
  Term_Name = "TermName",
  Student_ID = "StudentID",
  Subject = "Subject",
  Test_RIT_Score = "TestRITScore",
  Test_Percentile = "TestPercentile",
  Achievement_Quintile = "AchievementQuintile",
  Percent_Correct = "PercentCorrect",
  Rapid_Guessing_Percentage = "RapidGuessingPercentage",
  Lexile_Score = "LexileScore",
  Projected_Proficiency_Level = "ProjectedProficiencyLevel3",
}

type UploadDictionary = {
  [key in Column]: string;
};

const UploadOverviewsContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const standards = useRecoilValue(standardsAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);
  const overviews = useRecoilValue(overviewsAtom);

  const searchRecords = (newRecord: OverviewDataRecord) => {
    const matchingRecords = overviews.filter(
      (existingRecord) =>
        existingRecord.termName === newRecord.termName &&
        existingRecord.subject === newRecord.subject &&
        existingRecord.sid === newRecord.sid
    );
    return matchingRecords.length > 0 ? true : false;
  };

  const parseSubject = (subject: string) => {
    if (subject === "Mathematics") {
      return "Math";
    } else if (subject === "Language Arts") {
      return "RLA";
    } else {
      return subject;
    }
  };
  const fromWebsite = async (value: UploadDictionary) => {
    const record = {
      termName: value[Column.Term_Name] || "",
      sid: value[Column.Student_ID] || "",
      subject: parseSubject(value[Column.Subject]) || "",
      testRITScore: value[Column.Test_RIT_Score] || "",
      testPercentile: value[Column.Test_Percentile] || "",
      achievementQuintile: value[Column.Achievement_Quintile] || "",
      percentCorrect: value[Column.Percent_Correct] || "",
      rapidGuessingPercentage: value[Column.Rapid_Guessing_Percentage] || "",
      lexileScore: value[Column.Lexile_Score] || "",
      projectedProficiencyLevel: value[Column.Projected_Proficiency_Level] || "",
      id: "",
    };

    //const found = searchRecords(record);
    let resultId: string | null = "";
    resultId = await addDoc({ col: "overviews", data: record });
    // if (!found) {
    //   resultId = await addDoc({ col: "standards", data: record });
    // }
    return resultId ? resultId : "";
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<UploadDictionary>(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            const resultId = fromWebsite(value);
            promises.push(resultId);
          });
        },
        header: true,
      });
      await Promise.all(promises);
      setLoading(false);
    }
  };

  return (
    <UploadOverviewsForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
    />
  );
};

export default UploadOverviewsContainer;
