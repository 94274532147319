import { SyntheticEvent } from "react";
import { Avatar, Box, Paper, Button, Typography, CircularProgress } from "@mui/material";

type Props = {
  loading: boolean;
  successMessage: string;
  changeHandler: (event: SyntheticEvent) => void;
  handleSubmission: () => void;
  selectedFile: File | null | undefined;
};

export default function UploadOverviewsForm({
  loading,
  successMessage,
  changeHandler,
  handleSubmission,
  selectedFile,
}: Props) {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "1em",
        }}
      >
        <Avatar sx={{ width: 120, height: 120, mb: 3 }} src="../TGPicon.png" />
      </Box>
      <Paper>
        <Box sx={{ textAlign: "center", pt: 3 }}>
          <Typography variant="h1">Upload Overviews</Typography>
        </Box>
        <Box sx={{ textAlign: "center", padding: 3 }}>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              m: 2,
              mb: 2,
            }}
          >
            <CircularProgress size={60} sx={{ mb: 2 }} />
          </Box>
        ) : (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            {successMessage && <Typography variant="h4">{successMessage}</Typography>}
            <input
              id="uploadFile"
              type="file"
              name="file"
              style={{ display: "none" }}
              onChange={changeHandler}
            />
            <label htmlFor="uploadFile">
              {" "}
              <Button variant="contained" sx={{ margin: 3 }} component="span">
                Choose File
              </Button>
            </label>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 3 }}
              onClick={handleSubmission}
            >
              Submit
            </Button>
            {selectedFile && <Typography>{selectedFile.name}</Typography>}
          </Box>
        )}
      </Paper>
    </>
  );
}
