import { ScoreRecord } from "../types/types";
// eurekaModulesApi.js
// import { eurekaModuleState } from "./eurekaModuleAtom";
// import { onSnapshot } from "firebase/firestore";
// import { getFirestore } from "firebase/firestore";
export const makeSIDSixDigits = (score: ScoreRecord) => {
  let sid = "";
  const zeros = 6 - score.sid.length;
  for (let i = 0; i < zeros; i++) {
    sid = sid += "0";
  }
  sid = sid += score.sid;
  return sid;
};




// export const fetchEurekaModules = () => {
//   const eurekaModulesRef = getFirestore.collection("eurekaLessons"); // Replace with your collection name

//   onSnapshot(eurekaModulesRef, (snapshot) => {
//     const modules = [];
//     snapshot.forEach((doc) => {
//       const data = doc.data();
//       const module = data.module;
//       if (!modules.includes(module)) {
//         modules.push(module);
//       }
//     });

//     eurekaModuleState(modules); // Set the Recoil atom with the fetched modules
//   });
// };

