import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import { standardsAtom } from "../../recoil/standardsAtom";
import UploadSkillsForm from "./UploadSkillsForm";
import { Standard } from "../../types/types";
import { RIT_RANGES, SUBJECT } from "../../libraries/objects";
import { SelectChangeEvent } from "@mui/material";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

enum Column {
  Strand_Number = "Strand Number",
  Strand_Name = "Strand Name",
  Strand_Label = "Strand Label",
  Standard_Number = "Standard Number",
  Standard_Number_MAP = "Standard Number MAP",
  Standard_Label = "Standard Label",
  Grade = "Grade",
  Subject = "Subject",
  Type = "Type",
}

const IGNORE = [
  "Whole Number and Decimal Computations",
  "Number Patterns and Concepts of Expressions",
  "Use Place Value: Whole Numbers and Decimals",
  "Two-Dimensional Shapes & Three-Dimensional Solids",
  "Solve Problems Involving Measurement",
  "Represent and Solve Problems",
  "Represent and Generate Fractions",
  "Organizing, Displaying, and Interpreting Data",
  "Monetary Transactions",
  "Geometry and Measurement",
  "Fraction Computations",
  "Data Analysis and Monetary Transactions",
  "Computations and Algebraic Relationships",
  "Word Parts, Relationships, and Origins",
  "Text Structure and Graphic Features",
  "Purpose and Point of View",
  "Multiple Genres",
  "Language and Literary Devices",
  "Literary Texts",
  "Informational Texts",
  "Context Clues and Reference",
  "Author's Purpose and Craft",
];

type UploadDictionary = {
  row: string;
};

const UploadSkillsContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const standards = useRecoilValue(standardsAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);
  const [subject, setSubject] = useState<string>(SUBJECT.MATH);

  const handleSubjectChange = (event: SelectChangeEvent) => {
    setSubject(event.target.value);
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<UploadDictionary>(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          let record = {
            skill: "",
            standardNumber: "",
            rit: subject === "Math" ? 111 : 131,
            subject: subject,
          };
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            if (
              value.row.includes("Numerical Representations and Relationships") ||
              value.row.includes("Foundational Language Skills: Vocabulary")
            ) {
              record.rit = record.rit + 10;
              record.skill = "";
              record.standardNumber = "";
            } else {
              const standardNumber = value.row.slice(0, value.row.indexOf(":"));
              const standard = standards.find(
                (standard) => standard.standardNumberMAP === standardNumber
              );
              if (standard) {
                record.standardNumber = standard.standardNumberMAP;
              } else if (record.standardNumber !== "") {
                if (value.row.slice(0, 2) === "<<") {
                  record.skill = "<<No Skills for This Standard and RIT Score>>";
                  const RIT_KEY = String(record.rit) as keyof typeof RIT_RANGES;
                  addDoc({
                    col: "skills",
                    data: { ...record, rit: RIT_RANGES[RIT_KEY] },
                  });
                  record.standardNumber = "";
                  record.skill = "";
                } else {
                  const RIT_KEY = String(record.rit) as keyof typeof RIT_RANGES;
                  if (!IGNORE.includes(value.row)) {
                    record.skill = value.row;
                    addDoc({ col: "skills", data: { ...record, rit: RIT_RANGES[RIT_KEY] } });
                  }
                }
              }
            }
          });
        },
        header: true,
      });
      await Promise.all(promises);
      setLoading(false);
    }
  };

  return (
    <UploadSkillsForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
      subject={subject}
      handleSubjectChange={handleSubjectChange}
    />
  );
};

export default UploadSkillsContainer;
