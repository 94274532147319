import React, { Dispatch, MouseEvent, SetStateAction } from "react";
import { filteredStandardsAtom } from "../../recoil/standardsAtom";
import { useRecoilValue } from "recoil";
import StandardMenuItem from "./StandardMenuItem";

type Props = {
  setSelectedStandardNumber: Dispatch<SetStateAction<string | null>>;
  selectedStandardNumber: string | null;
};

const StandardSideMenu = ({ setSelectedStandardNumber, selectedStandardNumber }: Props) => {
  const standards = useRecoilValue(filteredStandardsAtom);
  const handleSelectedStandardNumber = (event: MouseEvent<HTMLButtonElement>) => {
    setSelectedStandardNumber(event.currentTarget.name as string);
  };
  return (
    <>
      {standards.map((standard) => (
        <StandardMenuItem
          standard={standard}
          key={standard.id}
          handleSelectedStandardNumber={handleSelectedStandardNumber}
          selectedStandardNumber={selectedStandardNumber}
        />
      ))}
    </>
  );
};

export default StandardSideMenu;
