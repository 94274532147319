import { atom } from "recoil";
import { OverviewDataRecord } from "../types/types";

export const overviewsAtom = atom<OverviewDataRecord[]>({
  key: "overviews",
  default: [],
});

export const overviewsResetAtom = atom({
  key: "overviewsReset",
  default: false,
});
