export enum PERMISSION {
  EDIT_STAFF = "Edit Staff",
  EDIT_SETTINGS = "Edit Settings",
  SEE_ALL_ROASTER = "See All Roasters",
}

export const PERMISSIONS: Array<PERMISSION> = [
  PERMISSION.EDIT_STAFF,
  PERMISSION.EDIT_SETTINGS,
  PERMISSION.SEE_ALL_ROASTER,
];

export enum SettingsSections {
  PERIODS = "Narrative Periods",
  SECTIONS = "Narrative Sections",
  OBJECTIVES = "Narrative Objectives",
  OVERRIDE = "Roster Override",
}

export enum COLLECTION {
  daysPerWeek = "daysPerWeek",
  incidentTypes = "incidentTypes",
  reports = "reports",
  staff = "staff",
}

export const COLLECTIONS = Object.values(COLLECTION);

export const RIT_RANGES = {
  "121": "121-130",
  "131": "131-140",
  "141": "141-150",
  "151": "151-160",
  "161": "161-170",
  "171": "171-180",
  "181": "181-190",
  "191": "191-200",
  "201": "201-210",
  "211": "211-220",
  "221": "221-230",
  "231": "231-240",
  "241": "241-250",
  "251": "251-260",
  "261": "261-270",
  "271": "271-280",
  "281": "281-290",
};

export enum TERM {
  FALL_2324 = "Fall 2023-2024",
  WINTER_2324 = "Winter 2023-2024",
  SPRING_2324 = "Spring 2023-2024",
}

export enum SUBJECT {
  MATH = "Math",
  RLA = "RLA",
  SCIENCE = "Science",
}
