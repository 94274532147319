import { atom } from "recoil";
import { SkillRecord } from "../types/types";

export const skillsAtom = atom<SkillRecord[]>({
  key: "skills",
  default: [],
});

export const skillsResetAtom = atom({
  key: "skillsReset",
  default: false,
});
