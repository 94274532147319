import { GetRecoilValue, atom, selector } from "recoil";
import { EurekaLessonRecord, Homeroom, Student } from "../types/types";
import { selectedHomeroomIdsAtom } from "./filtersAtom";

export const filteredStudentsGetter = ({ get }: { get: GetRecoilValue }) => {
  const students = get(studentsAtom);
  const selectedHomeroomIds = get(selectedHomeroomIdsAtom);
  const filteredStudents = students.filter((student) =>
    selectedHomeroomIds.includes(student.homeroom)
  );
  const sortedStudents = filteredStudents.sort((a, b) =>
    a.childLastName.localeCompare(b.childLastName)
  );

  return sortedStudents;
};

//****** HOMEROOM ATOMS ******//

export const homeroomsAtom = atom<Homeroom[]>({
  key: "homerooms",
  default: [],
});

//****** STUDENT ATOMS ******//

export const studentsAtom = atom<Student[]>({
  key: "students",
  default: [],
});

export const filteredStudentsAtom = selector({
  key: "filteredStudents",
  get: filteredStudentsGetter,
});

export const eurekaLessonsAtom = atom<EurekaLessonRecord[]>({
  key: "eurekaLessons",
  default: [],
});
