import { Box, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { RawStudentInterface } from "../../hooks/useBootstrapAPIEffect";
import { Student } from "../../types/types";
import { useRecoilValue } from "recoil";
import { overviewsAtom } from "../../recoil/overviewsAtom";
import { selectedSubjectAtom } from "../../recoil/filtersAtom";

type Props = {
  student: Student;
};

const StudentRow = ({ student }: Props) => {
  const overviews = useRecoilValue(overviewsAtom);
  const selectedSubject = useRecoilValue(selectedSubjectAtom);
  const RLAOverview = overviews.find(
    (overview) =>
      overview.sid === String(Number(student.SID)) && overview.subject === "Language Arts"
  );
  const MathOverview = overviews.find(
    (overview) => overview.sid === String(Number(student.SID)) && overview.subject === "Mathematics"
  );
  const formatProficiency = () => {
    if (selectedSubject === "Math") {
      if (MathOverview?.projectedProficiencyLevel) {
        return `Math Proficiency: ${MathOverview.projectedProficiencyLevel}`;
      } else {
        return "";
      }
    }
    if (selectedSubject === "RLA") {
      if (RLAOverview?.projectedProficiencyLevel) {
        return `RLA Proficiency: ${RLAOverview.projectedProficiencyLevel}`;
      } else {
        return "";
      }
    }
    return <></>;
  };

  const getProficiencyColor = () => {
    if (selectedSubject === "Math") {
      if (MathOverview?.projectedProficiencyLevel === "Did not Meet") {
        return "notMetProficiency";
      } else if (MathOverview?.projectedProficiencyLevel === "Approaches") {
        return "approachesProficiency";
      } else if (MathOverview?.projectedProficiencyLevel === "Meets") {
        return "metProficiency";
      } else if (RLAOverview?.projectedProficiencyLevel === "Masters") {
        return "masteredProficiency";
      } else {
        return "gray";
      }
    }
    if (selectedSubject === "RLA") {
      if (RLAOverview?.projectedProficiencyLevel === "Did not Meet") {
        return "notMetProficiency";
      } else if (RLAOverview?.projectedProficiencyLevel === "Approaches") {
        return "approachesProficiency";
      } else if (RLAOverview?.projectedProficiencyLevel === "Meets") {
        return "metProficiency";
      } else if (RLAOverview?.projectedProficiencyLevel === "Masters") {
        return "masteredProficiency";
      } else {
        return "gray";
      }
    }
  };
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }} columns={24}>
        <Grid item xs={24} sm={3} md={6}></Grid>
        <Grid item xs={24} sm={6} md={4}>
          <Typography component="span">{`${student.childFirstName} ${student.childLastName}`}</Typography>
        </Grid>
        <Grid item xs={24} sm={6} md={4}>
          <Chip
            sx={{ ml: 1 }}
            label={`${RLAOverview?.lexileScore ? `Lexile: ${RLAOverview.lexileScore}` : ""}`}
          />
        </Grid>
        <Grid item xs={24} sm={6} md={4}>
          <Chip color={getProficiencyColor()} sx={{ ml: 1 }} label={formatProficiency()} />
        </Grid>
        <Grid item xs={24} sm={3} md={6}></Grid>
      </Grid>
    </>
  );
};

export default StudentRow;
