import { useContext, useState, useCallback } from "react";
import { AppBar, Box, Toolbar, Typography, Button, IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../providers/AuthProvider";
import Weblinks from "./Weblinks";
import MobileLinks from "./MobileLinks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useRecoilValue } from "recoil";
import MenuDropdown from "./MenuDropdown";

const Navbar = () => {
  const { logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { width } = useWindowDimensions();
  const collapse = width > 1000 ? false : true;

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography sx={{ flexGrow: 1, fontWeight: 700 }}>MAP Data Tool</Typography>
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuDropdown handleClose={handleClose} />
            </Menu>
          </>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
