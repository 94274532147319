import { useContext } from "react";
import { MenuItem, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { useRecoilValue } from "recoil";
import { AuthContext } from "../../providers/AuthProvider";
// import SelectOptions from "./SelectOptions";

type Props = {
  handleClose: () => void;
};

export default function MenuDropdown({ handleClose }: Props) {
  const { logout } = useContext(AuthContext);
  const { currentAuthUser } = useContext(AuthContext);
  console.log(currentAuthUser);

  return (
    <>
      {/* can set permissions here */}
      {true && (
        <>
          <Box sx={{ padding: 2 }}>
            {/* <SelectOptions /> */}
            {currentAuthUser && currentAuthUser.email === "ryan@thegatheringplacek12.org" && (
              <>
                <MenuItem onClick={handleClose} sx={{ mt: 1 }}>
                  <Link to="/upload-standards" className="navLinkMenu">
                    Upload Standards
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/upload-scores" className="navLinkMenu">
                    Upload Scores
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/upload-skills" className="navLinkMenu">
                    Upload Skills
                  </Link>
                </MenuItem>
              </>
            )}
            <MenuItem onClick={handleClose}>
              <Link to="/standard-rit-report" className="navLinkMenu">
                Students By Standard
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/upload-eureka-lessons" className="navLinkMenu">
               Upload Eureka Lessons
              </Link>
            </MenuItem>
          </Box>
        </>
      )}
    </>
  );
}
