import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import { standardsAtom } from "../../recoil/standardsAtom";
import UploadScoresForm from "./UploadScoresForm";
import { Standard } from "../../types/types";
import { RIT_RANGES, SUBJECT, TERM } from "../../libraries/objects";
import { scoresAtom } from "../../recoil/scoresAtom";
import { SelectChangeEvent } from "@mui/material";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

enum Column {
  Strand_Number = "Strand Number",
  Strand_Name = "Strand Name",
  Strand_Label = "Strand Label",
  Standard_Number = "Standard Number",
  Standard_Number_MAP = "Standard Number MAP",
  Standard_Label = "Standard Label",
  Grade = "Grade",
  Subject = "Subject",
  Type = "Type",
}

type UploadDictionary = {
  row: string;
};

const checkForDuplicates = () => {};

const UploadScoresContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const standards = useRecoilValue(standardsAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);
  const scores = useRecoilValue(scoresAtom);
  const [term, setTerm] = useState<string>(TERM.FALL_2324);
  const [subject, setSubject] = useState<string>(SUBJECT.MATH);
  const handleSubjectChange = (event: SelectChangeEvent) => {
    setSubject(event.target.value);
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleTermChange = (event: SelectChangeEvent) => {
    setTerm(event.target.value);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<UploadDictionary>(selectedFile, {
        complete: async function (results) {
          const length = results.data.length;
          let record = { sid: "", standardNumber: "", rit: "", termName: term, subject: subject };
          // Iterate through the data array
          for (let index = 0; index < results.data.length; index++) {
            const value = results.data[index];

            // Check if it's the last item in the array
            if (index === results.data.length - 1) {
              setLast(true);
            }

            if (value.row.includes("I ID:")) {
              // Extract the ID from the row
              const id = value.row.split("I ID:")[1];
              record.sid = id;
            } else {
              // Extract the standard number from the row
              const standardNumber = value.row.slice(0, value.row.indexOf(":"));
              const standard = standards.find(
                (standard) => standard.standardNumberMAP === standardNumber
              );

              if (standard) {
                record.standardNumber = standard.standardNumberMAP;
              }

              if (value.row.includes("DEVELOP these skills (")) {
                // Extract RIT number from the row
                const ritFull = value.row.split("DEVELOP these skills (")[1];
                const ritNumber = ritFull.slice(0, 7);
                record.rit = ritNumber;

                // Check if the score for the current record exists
                const check = scores.find(
                  (score) =>
                    score.sid === record.sid &&
                    score.termName === record.termName &&
                    score.subject === record.subject
                );

                if (!check) {
                  // Add the record to the scores collection
                  const result = addDoc({ col: "scores", data: record });
                  promises.push(result);
                }

                // Reset the record object for the next iteration
                record = {
                  sid: record.sid,
                  standardNumber: "",
                  rit: "",
                  termName: term,
                  subject: subject,
                };
              }
            }
          }
          console.log(promises);
          await Promise.all(promises);
          console.log(promises);
          setLoading(false);
        },
        header: true,
      });
    }
  };

  return (
    <UploadScoresForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
      handleTermChange={handleTermChange}
      term={term}
      handleSubjectChange={handleSubjectChange}
      subject={subject}
    />
  );
};

export default UploadScoresContainer;
