import React, { useEffect, useState } from "react";
import { RIT_RANGES } from "../../libraries/objects";

import { Box, CircularProgress, Typography } from "@mui/material";
import RITBox from "./RITBox";
import { useRecoilValue } from "recoil";
import { scoresAtom } from "../../recoil/scoresAtom";

type Props = {
  selectedStandardNumber: string | null;
};

const StandardRITReport = ({ selectedStandardNumber }: Props) => {
  const RIT_ARRAY = Object.values(RIT_RANGES);
  const scores = useRecoilValue(scoresAtom);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    const filteredScores = scores.filter(
      (score) => score.standardNumber === selectedStandardNumber
    );
    setLength(filteredScores.length);
  }, [selectedStandardNumber]);

  return (
    <>
      {selectedStandardNumber ? (
        <>
          {length > 0 ? (
            <>
              {RIT_ARRAY.map((rit) => (
                <RITBox key={rit} rit={rit} selectedStandardNumber={selectedStandardNumber} />
              ))}
            </>
          ) : (
            <Typography align="center" variant="h2" sx={{ mt: 2 }}>
              No scores for this standard
            </Typography>
          )}
        </>
      ) : (
        <Typography align="center" variant="h2" sx={{ mt: 2 }}>
          Please select a standard
        </Typography>
      )}
    </>
  );
};

export default StandardRITReport;
