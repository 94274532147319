import { Box, Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import StandardRITReportContainer from "../components/StandardRITReport/StandardRITReportContainer";

const StandardRITReportPage = () => {
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Navbar />
        <Container maxWidth="xl" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
          <StandardRITReportContainer />
        </Container>
      </Box>
    </>
  );
};

export default StandardRITReportPage;
