import React, { MouseEvent, useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { ScoreRecord, StandardRecord } from "../../types/types";
import { useRecoilValue } from "recoil";
import { scoresAtom } from "../../recoil/scoresAtom";
import { selectedHomeroomIdsAtom } from "../../recoil/filtersAtom";
import { filteredStudentsAtom } from "../../recoil/atoms";

type Props = {
  standard: StandardRecord;
  handleSelectedStandardNumber: (event: MouseEvent<HTMLButtonElement>) => void;
  selectedStandardNumber: string | null;
};

const StandardMenuItem = ({
  standard,
  handleSelectedStandardNumber,
  selectedStandardNumber,
}: Props) => {
  const scores = useRecoilValue(scoresAtom);
  const selectedHomeroomIds = useRecoilValue(selectedHomeroomIdsAtom);
  const filteredStudents = useRecoilValue(filteredStudentsAtom);
  const filteredStudentIds = filteredStudents.map((student) => String(Number(student.SID)));
  const [filteredScores, setFilteredScores] = useState<ScoreRecord[]>([]);

  useEffect(() => {
    const tempFilteredScores = scores.filter(
      (score) =>
        score.standardNumber === standard.standardNumberMAP &&
        filteredStudentIds.includes(score.sid)
    );
    setFilteredScores(tempFilteredScores);
  }, [filteredStudents]);

  return (
    <Box sx={{ padding: 0 }} key={standard.id}>
      <Button
        sx={{ margin: 1, padding: 1, textTransform: "none" }}
        name={standard.standardNumberMAP}
        onClick={handleSelectedStandardNumber}
        variant={selectedStandardNumber === standard.standardNumberMAP ? "outlined" : "text"}
      >
        <Typography
          sx={{
            fontSize: 12,
            textAlign: "left",
            color: selectedStandardNumber === standard.standardNumberMAP ? "#333" : "#333",
          }}
        >
          <b style={{ color: standard.type === "Readiness" ? "#118016" : "#DBAD1F" }}>
            {standard.strandName}
          </b>
          <span
            style={{ color: standard.type === "Readiness" ? "#118016" : "#DBAD1F" }}
          >{` ${standard.standardNumber} `}</span>
          {` ${standard.standardLabel}`}
          <span style={{ color: "#333" }}>{` (${filteredScores.length})`}</span>
        </Typography>
      </Button>
      <Divider />
    </Box>
  );
};

export default StandardMenuItem;
