import { GetRecoilValue, atom, selector } from "recoil";
import { Homeroom, StandardRecord, Student } from "../types/types";
import { selectedGradeAtom, selectedSubjectAtom } from "./filtersAtom";

export const filteredStandardsGetter = ({ get }: { get: GetRecoilValue }) => {
  const standards = get(standardsAtom);
  const selectedGrade = get(selectedGradeAtom);
  const selectedSubject = get(selectedSubjectAtom);
  const filteredStandards = standards.filter(
    (standard) => standard.grade === selectedGrade && standard.subject === selectedSubject
  );
  filteredStandards.sort((a, b) =>
    a.standardNumber > b.standardNumber ? 1 : b.standardNumber > a.standardNumber ? -1 : 0
  );

  return filteredStandards;
};

export const standardsAtom = atom<StandardRecord[]>({
  key: "standards",
  default: [],
});

export const filteredStandardsAtom = selector({
  key: "filteredStandards",
  get: filteredStandardsGetter,
});

export const standardsResetAtom = atom({
  key: "standardsReset",
  default: false,
});
