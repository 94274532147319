import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  selectedGradeAtom,
  selectedHomeroomIdsAtom,
  selectedLessonAtom,
  selectedModuleAtom,
  selectedSubjectAtom,
} from "../../recoil/filtersAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { eurekaLessonsAtom, homeroomsAtom } from "../../recoil/atoms";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GRADES = ["K", "1", "2", "3", "4", "5", "6", "7", "8"];
const SUBJECTS = ["Math", "RLA"];

const Filters = () => {
  const [selectedGrade, setSelectedGrade] = useRecoilState(selectedGradeAtom);
  const [selectedSubject, setSelectedSubject] = useRecoilState(selectedSubjectAtom);
  const [selectedHomeroomIds, setSelectedHomeroomIds] = useRecoilState(selectedHomeroomIdsAtom);
  const homerooms = useRecoilValue(homeroomsAtom);
  const sortedHomerooms = [...homerooms].sort((a, b) => a.name.localeCompare(b.name));
  const [personName, setPersonName] = useState<string[]>([]);

  const [selectedModule, setSelectedModule] = useRecoilState(selectedModuleAtom);
  const [selectedLesson, setSelectedLesson] = useRecoilState(selectedLessonAtom);
  const eurekaLessons = useRecoilValue(eurekaLessonsAtom);
  const [availableModules, setAvailableModules] = useState<string[]>([]);
  const [availableLessons, setAvailableLessons] = useState<string[]>([]);

  console.log(eurekaLessons);
  useEffect(() => {
    const tempArray: string[] = [];
    eurekaLessons.forEach((lesson) => {
      if (!tempArray.includes(lesson.module)) {
        tempArray.push(lesson.module);
      }
    });
    setAvailableModules(tempArray);
  }, [eurekaLessons]);

  useEffect(() => {
    const tempLesson: string[] = [];
    eurekaLessons.forEach((lesson) => {
      if (!tempLesson.includes(lesson.lessons)) {
        tempLesson.push(lesson.lessons);
      }
    });
    setAvailableLessons(tempLesson);
  }, [eurekaLessons]);

  const handleGradeChange = (event: SelectChangeEvent) => {
    setSelectedGrade(event.target.value);
  };

  const handleSubjectChange = (event: SelectChangeEvent) => {
    setSelectedSubject(event.target.value as string);
    console.log(event.target.value);
  };

  const handleModuleChange = (event: SelectChangeEvent) => {
    setSelectedModule(event.target.value);
  };
  const handleLessonChange = (event: SelectChangeEvent) => {
    setSelectedLesson(event.target.value);
  };

  const handleHomeroomChange = (event: SelectChangeEvent<typeof selectedHomeroomIds>) => {
    const {
      target: { value },
    } = event;
    setSelectedHomeroomIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };



  const renderHomerooms = (selectedIds: string[]) => {
    return selectedIds.map((id) => {
      const homeroom = homerooms.find((homeroom) => homeroom.id === id);
      return `${homeroom?.name}, `;
    });
  };
  return (
    <Paper>
      <Typography align="center" variant="h4" sx={{ p: 1 }}>
        Filters
      </Typography>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="grade-select-label">Select Grade</InputLabel>
            <Select
              variant="outlined"
              label={"Select Grade"}
              labelId="grade-select-label"
              onChange={handleGradeChange}
              value={selectedGrade}
              fullWidth
            >
              {GRADES.map((grade) => (
                <MenuItem key={grade} value={grade}>
                  {grade}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="subject-select-label">Select Subject</InputLabel>
            <Select
              variant="outlined"
              label={"Select Subject"}
              labelId="subject-select-label"
              onChange={handleSubjectChange}
              value={selectedSubject}
              fullWidth
            >
              {SUBJECTS.map((subject) => (
                <MenuItem key={subject} value={subject}>
                  {subject}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="homeroom-select-label">Select Homerooms</InputLabel>
            <Select
              labelId="homeroom-select-label"
              label="Select Homerooms"
              multiple
              value={selectedHomeroomIds}
              onChange={handleHomeroomChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={renderHomerooms}
              MenuProps={MenuProps}
            >
              {sortedHomerooms.map((homeroom) => (
                <MenuItem key={homeroom.id} value={homeroom.id}>
                  <Checkbox checked={selectedHomeroomIds.indexOf(homeroom.id) > -1} />
                  <ListItemText primary={homeroom.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {selectedSubject === "Math" && false && (
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="select-module-label">Select Module</InputLabel>
                <Select
                  variant="outlined"
                  label={"Select Module"}
                  labelId="select-module-label"
                  // onClick={moduleListHandler}
                  onChange={handleModuleChange}
                  value={selectedModule}
                  fullWidth
                >
                  {availableModules.map((module) => (
                    <MenuItem key={module} value={module}>
                      {module}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="select-lesson-label">Select Lesson</InputLabel>
                <Select
                  variant="outlined"
                  label={"Select Lesson"}
                  labelId="subject-lesson-label"
                  onChange={handleLessonChange}
                  value={selectedLesson}
                  fullWidth
                >
                  {availableLessons.map((lesson) => (
                    <MenuItem key={lesson} value={lesson}>
                      {lesson}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default Filters;
