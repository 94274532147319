import {
  Homeroom,
  Student,
  Staff,
  StandardRecord,
  SkillRecord,
  ScoreRecord,
  OverviewDataRecord,
  EurekaLessonRecord,
} from "../types/types";

export const parseHomeroomResponse = (response: Homeroom[]): Homeroom[] =>
  response.map((homeroom: Homeroom) => ({
    id: homeroom?.id ?? "",
    name: homeroom?.name ?? "",
    grade: homeroom?.grade ?? "",
  }));

export const parseStudentResponse = (response: Student[]): Student[] =>
  response.map((student: Student) => ({
    id: student?.id ?? "",
    childFirstName: student?.childFirstName ?? "",
    childLastName: student?.childLastName ?? "",
    enrollStatus: student?.enrollStatus ?? "",
    homeroom: student?.homeroom ?? "",
    SID: student?.SID ?? "",
  }));

export const parseStaffResponse = (response: Staff[]): Staff[] =>
  response.map((staff: Staff) => ({
    id: staff?.id ?? "",
    firstName: staff?.firstName ?? "",
    lastName: staff?.lastName ?? "",
    email: staff?.email ?? "",
    permissions: staff?.permissions ?? [],
    homerooms: staff?.homerooms ?? [],
  }));

export const parseStandardsResponse = (response: StandardRecord[]): StandardRecord[] =>
  response.map((record: StandardRecord) => ({
    id: record?.id ?? "",
    strandNumber: record.strandNumber ?? "",
    strandName: record.strandName ?? "",
    strandLabel: record.strandLabel ?? "",
    standardNumber: record.standardNumber ?? "",
    standardLabel: record.standardLabel ?? "",
    grade: record.grade ?? "",
    subject: record.subject ?? "",
    type: record.type ?? "",
    lastUpdatedAt: record.lastUpdatedAt ?? "",
    createdAt: record.createdAt ?? "",
    standardNumberMAP: record.standardNumberMAP ?? "",
  }));

export const parseScoresResponse = (response: ScoreRecord[]): ScoreRecord[] =>
  response.map((record: ScoreRecord) => ({
    id: record?.id ?? "",
    rit: record.rit ?? "",
    sid: record.sid ?? "",
    standardNumber: record.standardNumber ?? "",
    lastUpdatedAt: record.lastUpdatedAt ?? "",
    createdAt: record.createdAt ?? "",
    termName: record.termName ?? "",
    subject: record.subject ?? "",
  }));

export const parseSkillsResponse = (response: SkillRecord[]): SkillRecord[] =>
  response.map((record: SkillRecord) => ({
    id: record?.id ?? "",
    rit: record.rit ?? "",
    skill: record.skill ?? "",
    standardNumber: record.standardNumber ?? "",
    lastUpdatedAt: record.lastUpdatedAt ?? "",
    createdAt: record.createdAt ?? "",
  }));

export const parseOverviewsResponse = (response: OverviewDataRecord[]): OverviewDataRecord[] =>
  response.map((record: OverviewDataRecord) => ({
    id: record?.id ?? "",
    testRITScore: record.testRITScore ?? "",
    sid: record.sid ?? "",
    testPercentile: record.testPercentile ?? "",
    lastUpdatedAt: record.lastUpdatedAt ?? "",
    createdAt: record.createdAt ?? "",
    termName: record.termName ?? "",
    subject: record.subject ?? "",
    achievementQuintile: record.achievementQuintile ?? "",
    percentCorrect: record.percentCorrect ?? "",
    rapidGuessingPercentage: record.rapidGuessingPercentage ?? "",
    lexileScore: record.lexileScore ?? "",
    projectedProficiencyLevel: record.projectedProficiencyLevel ?? "",
  }));


  export const parseEurekaLessonResponse=(response :EurekaLessonRecord[]):EurekaLessonRecord[]=>
  response.map((record : EurekaLessonRecord)=>({
    subject: record.subject?? "",
    grade: record.grade ?? "",
    module: record.module ??"",
    topic: record.topic ?? "",
    lessons: record.lessons?? "",
    standard: record.standard ?? "",
    id: record.id ?? "",
    lastUpdatedAt: record.lastUpdatedAt ?? "",
    createdAt: record.createdAt ?? "",


  }));