import { atom } from "recoil";

export const selectedGradeAtom = atom<string>({
  key: "selectedGrade",
  default: "3",
});

export const selectedSubjectAtom = atom<string>({
  key: "selectedSubject",
  default: "Math",
});

export const selectedHomeroomIdsAtom = atom<string[]>({
  key: "selectedHomeroomIds",
  default: [],
});

export const selectedModuleAtom=atom<string>({
  key :"selectedModule",
  default:"None",
})

export const selectedLessonAtom=atom<string>({
  key:"slectedModule",
  default:"None"

})
